export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('focus', {
    mounted(el: HTMLElement | null) {
      if (el === null) return
      if (el instanceof HTMLInputElement) {
        el.focus();
      } else {
        el.querySelector('input')?.focus();
      }
    }
  })
})