import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_GLp6kzVXKq from "/vercel/path0/node_modules/nuxt-pdfeasy/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import colors_SVBUrxEU7r from "/vercel/path0/plugins/colors.ts";
import dragscroll_U3dCpB8oXl from "/vercel/path0/plugins/dragscroll.ts";
import focus_9NFqPXxCey from "/vercel/path0/plugins/focus.ts";
import promotions_ZNYnD296Pw from "/vercel/path0/plugins/promotions.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import sonner_client_l6WBUspFNo from "/vercel/path0/plugins/sonner.client.ts";
import telinput_client_Xy5RU2Y4ye from "/vercel/path0/plugins/telinput.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_LcKgStRyi6,
  plugin_GLp6kzVXKq,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  colors_SVBUrxEU7r,
  dragscroll_U3dCpB8oXl,
  focus_9NFqPXxCey,
  promotions_ZNYnD296Pw,
  sentry_3AyO8nEfhE,
  sonner_client_l6WBUspFNo,
  telinput_client_Xy5RU2Y4ye
]