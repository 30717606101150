<template>
  <UiOverlay @close="closeOverlay()" :additional-padding="true" :flex-column="true">
    <UiOverlayClose @click="closeOverlay()" />
    <h4>{{ $t('account.bookings.deposit-report.title') }}</h4>
    <p class="grow text-base">
      {{ depositReportInfo }}
    </p>
    <div class="rounded-s bg-black-4 p-4 mt-8">
      <h6>{{ $t('account.bookings.deposit-report.questions') }}</h6>
      <div class="flex flex-wrap gap-4 mt-4">
        <a
          :href="'mailto:' + $t('account.bookings.deposit-report.mail')"
          class="py-3 px-6 mt-auto bg-purple bg-opacity-20 text-purple rounded-s text-sm no-underline sm:w-full sm:text-center cursor-pointer hover:bg-opacity-30 transition-colors"
          >{{ $t('account.bookings.deposit-report.mail') }}</a
        >
        <a
          :href="'tel:' + $t('account.bookings.deposit-report.phone')"
          class="py-3 px-6 mt-auto bg-purple bg-opacity-20 text-purple rounded-s text-sm no-underline sm:w-full sm:text-center cursor-pointer hover:bg-opacity-30 transition-colors"
          >{{ $t('account.bookings.deposit-report.phone') }}</a
        >
      </div>
    </div>
  </UiOverlay>
</template>

<script setup lang="ts">
const { depositReportVisible, depositReportInfo } = useOverlay();
const closeOverlay = () => (depositReportVisible.value = false);
</script>
