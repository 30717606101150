<template>
  <div
    class="relative border border-black-10 rounded-s px-4 py-3 hover:bg-black/[.015] transition-colors"
  >
    <label class="cursor-text" :for="uid">
      <p class="text-sm text-black-50 leading-tight select-none h-4">
        {{ title }}
      </p>
      <input
        class="w-full text-black placeholder-black-50 placeholder-opacity-50 p-0 m-0 outline-none leading-tight bg-[transparent] z-10"
        type="search"
        v-model="searchQuery"
        :placeholder="placeholder"
        :required="required"
        @blur="hideSuggestions()"
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        @focus="suggestionsVisible = true"
        :id="uid"
      />
    </label>
    <ul
      class="absolute left-0 top-full mt-1 suggestions bg-white rounded-s text-black font-sans border-spacing-4 shadow overflow-hidden border border-black-10 w-full w-min-full suggestions z-10"
      v-if="filteredData && filteredData.length && suggestionsVisible"
    >
      <li
        class="border-b border-black-10 px-4 py-2 cursor-pointer hover:bg-black-4"
        v-for="(suggestion, index) in filteredData.slice(0, 5)"
        :key="index"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.name }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content/dist/runtime/types';

// const { title, placeholder, modelValue } = defineProps(['title', 'placeholder', 'modelValue']);
const props = withDefaults(
  defineProps<{
    title: string;
    placeholder?: string;
    required?: boolean;
    modelValue: any;
  }>(),
  { required: false },
);
const emit = defineEmits(['update:modelValue']);

interface Country {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}

interface Countries extends ParsedContent {
  title: string;
  list: Country[];
}

const compontentInstance = getCurrentInstance();
let uid: string = `nationlality-input-${compontentInstance?.uid ?? createRandomId(8)}`;

const suggestionsVisible = ref(false);
function hideSuggestions(afterSelect = false) {
  setTimeout(
    () => {
      suggestionsVisible.value = false;
    },
    afterSelect ? 0 : 200,
  );
}

const { data: countries } = await useAsyncData('data-countries', () =>
  queryContent<Countries>('data').where({ title: 'Countries' }).find(),
);

const searchQuery = ref<string>(props.modelValue);

const suggestedCountries = ['ch', 'de', 'at', 'fr', 'it'];

const filteredData: Ref<Country[]> = computed(() => {
  if (!countries.value || countries.value.length < 1) return [];

  if (searchQuery.value) {
    return countries.value[0].list.filter((country) => {
      return country.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    });
  }

  const suggestions = countries.value[0].list.filter((country) =>
    suggestedCountries.includes(country.alpha2),
  );
  // sort according to intial "suggested Countries" array on return
  return suggestions.sort((a, b) => {
    return suggestedCountries.indexOf(a.alpha2) - suggestedCountries.indexOf(b.alpha2);
  });
});

const selectSuggestion = (suggestion: Country) => {
  emit('update:modelValue', suggestion.name);
  searchQuery.value = suggestion.name;
  hideSuggestions(true);
};
</script>
