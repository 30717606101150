import type { DirectiveBinding } from '@vue/runtime-core';
import type { GlobalColors } from '~/types/general';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('colors', {
    mounted(el: HTMLElement | null, binding: DirectiveBinding<GlobalColors>) {
      const target = ref(el);
      const targetIsVisible = ref(false);

      const { stop } = useIntersectionObserver(
        target,
        ([{ isIntersecting }]) => {
          targetIsVisible.value = isIntersecting;
        },
        {
          rootMargin: `-50% 0px -50% 0px`,
          threshold: 0,
        },
      );
      watch(
        targetIsVisible,
        (value) => {
          if (!value) return;
          // set dark "mode"
          const isDarkBg = useState<boolean>('darkBg', () => true);
          isDarkBg.value = binding.modifiers.dark || false;

          // set global colors
          const primary = binding.value.primary;
          const secondary = binding.value.secondary;
          const globalColors = useState<GlobalColors>('sectionColors', () => ({
            primary,
            secondary,
          }));
          globalColors.value = { primary, secondary };
        },
        {
          immediate: true,
        },
      );
    },
    // unmounted(el: HTMLElement | null) {
    //   // stop observing when element is unmounted
    //   if (el === null) return
    //   const target = ref(el);
    //   const { stop } = useIntersectionObserver(target, () => {}, {
    //     rootMargin: `-50% 0px -50% 0px`,
    //     threshold: 0,
    //   })
    // }
  });
});
