import * as Sentry from '@sentry/vue';

async function lazyLoadSentryIntegrations() {
  if (!process.client) return;

  const { Replay } = await import('@sentry/vue');
  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  );
}

function getSentryIntegrations() {
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  });

  return [browserTracing];
}

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn) {
      return;
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: getSentryIntegrations(),

      tracesSampleRate: 0.1,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'aabo.ch', 'thehireguys.ch'],

      replaysSessionSampleRate: 0.2, // Change in prod
      replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    });

    lazyLoadSentryIntegrations();
  },
});
