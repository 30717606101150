export const niceDate = (date: string | Date) => {
  // date format: 2023-11-29
  const { locale } = useI18n();
  if (typeof date === 'string') {
    return useDateFormat(date.replace('Z', ''), 'D. MMMM', { locales: locale.value ?? 'de' }).value;
  } else {
    return useDateFormat(date.toISOString().replace('Z', ''), 'D. MMMM', {
      locales: locale.value ?? 'de',
    }).value;
  }
};

export const dateToday = useDateFormat(
  new Date().toISOString().replace('Z', ''),
  'YYYY-MM-DD',
).value;
export const dateTomorrow = useDateFormat(
  new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toISOString().replace('Z', ''),
  'YYYY-MM-DD',
).value;
export const dateBookingFrom = useDateFormat(
  new Date(new Date().getTime() + 1000 * 60 * 60 * 36).toISOString().replace('Z', ''),
  'YYYY-MM-DD',
).value;
export const dateInEightWeeks = useDateFormat(
  new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7 * 8).toISOString().replace('Z', ''),
  'YYYY-MM-DD',
).value;

export function isSameDate(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function createRandomId(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function extractStartEndDates(path: string, isRelative = true) {
  // any domain value works here, absolute URL required by URL constructor
  const url = isRelative ? `https://thehireguys.ch${path}` : path;
  const constructedURL = new URL(url);
  const start = constructedURL.searchParams.get('start');
  const end = constructedURL.searchParams.get('end');

  return {
    start,
    end,
  };
}
