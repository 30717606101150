<template>
  <div class="flex items-center justify-center h-[100vh] w-full bg-white">
    <div class="px-4 max-w-4xl text-purple">
      <!-- <h1 class="leading-tight sm:text-3xl text-yellow">{{ $t('error.title') }}</h1> -->
      <h1 class="leading-tight sm:text-3xl text-purple">
        {{ $t('error.error') }} {{ error.statusCode }}
      </h1>
      <h5 class="font-wide">{{ error.message }}</h5>

      <ClientOnly>
        <NuxtLink
          @click="reloadNuxtApp()"
          class="inline-block py-3 px-6 mt-4 me-2 bg-purple text-pink rounded-s text-sm no-underline sm:w-full sm:text-center cursor-pointer"
          >{{ $t('error.reload') }}</NuxtLink
        >
      </ClientOnly>
      <NuxtLink
        :to="localePath({ name: 'index' })"
        class="inline-block py-3 px-6 mt-4 bg-purple/10 text-purple rounded-s text-sm no-underline sm:w-full sm:text-center"
        >{{ $t('error.cta') }}</NuxtLink
      >
    </div>
  </div>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const { error } = defineProps<{
  error: { statusCode: number; message: string };
}>();
console.error(error);
</script>
