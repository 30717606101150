export const useOverlay = () => {
  const signInVisible = useState('signInVisible', () => false);
  const signUpVisible = useState('signUpVisible', () => false);
  const enableSignUp = useState('enableSignUp', () => true);
  const depositReportVisible = useState('depositReportVisible', () => false);
  const depositReportInfo = useState('depositReportInfo', () => '');
  // lock body scroll while overlay is open
  watch(
    [signInVisible, signUpVisible, depositReportVisible],
    ([newValSignIn, newValSignUp, newValDeposit]) => {
      document.body.style.overflow = newValSignIn || newValSignUp || newValDeposit ? 'hidden' : '';
    },
  );

  return {
    signInVisible,
    signUpVisible,
    enableSignUp,
    depositReportVisible,
    depositReportInfo,
  };
};
