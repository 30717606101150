<template>
  <div
    class="overlay-outer fixed top-0 left-0 w-full h-full bg-white/50 flex z-20 popup backdrop-blur-md"
    @click="$emit('close')"
  >
    <div
      class="overlay-inner m-auto bg-white rounded-m shadow border border-black border-opacity-10 flex w-1/2 xl:w-11/12 sm:w-full sm:h-full max-h-[90vh] sm:max-h-full overflow-auto sm:rounded-none"
      :class="[additionalPadding ? 'px-8 pt-4 pb-8' : '', flexColumn ? 'flex-col' : '']"
      @click.stop
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    additionalPadding?: boolean;
    flexColumn?: boolean;
  }>(),
  {
    additionalPadding: false,
    flexColumn: false,
  },
);
const emit = defineEmits(['close']);

const onEscapePress = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('close');
  }
};

onMounted(() => {
  window.addEventListener('keydown', onEscapePress);
});

onUnmounted(() => {
  window.removeEventListener('keydown', onEscapePress);
});
</script>

<style scoped>
.overlay-inner {
  min-height: min(30rem, 90vh);
}
</style>

<style>
@media screen and (max-width: 639px) {
  body.has-fixed-mobile-cta .overlay-outer {
    top: 56px;
    height: calc(100% - 56px);
  }
}
</style>
