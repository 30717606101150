
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "fr",
  "it"
]

export const localeMessages = {
  "en": [],
  "de": [],
  "fr": [],
  "it": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US"
    },
    {
      "code": "de",
      "iso": "de-DE"
    },
    {
      "code": "fr",
      "iso": "fr-FR"
    },
    {
      "code": "it",
      "iso": "it-IT"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://thehireguys.ch",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "account/index": {
      "en": "/account",
      "de": "/konto",
      "fr": "/compte",
      "it": "/account"
    },
    "account/bookings/index": {
      "en": "/account/bookings",
      "de": "/konto/buchungen",
      "fr": "/compte/reservations",
      "it": "/account/prenotazioni"
    },
    "account/bookings/[id]/index": {
      "en": "/account/bookings/[id]",
      "de": "/konto/buchungen/[id]",
      "fr": "/compte/reservations/[id]",
      "it": "/account/prenotazioni/[id]"
    },
    "account/extend/[booking]/index": {
      "en": "/account/extend/[booking]/",
      "de": "/konto/verlaengern/[booking]/",
      "fr": "/compte/prolonger/[booking]/",
      "it": "/account/estendere/[booking]/"
    },
    "account/extend/[booking]/failed": {
      "en": "/account/extend/[booking]/failed",
      "de": "/konto/verlaengern/[booking]/fehlgeschlagen",
      "fr": "/compte/prolonger/[booking]/echec",
      "it": "/account/estendere/[booking]/fallito"
    },
    "account/extend/[booking]/success": {
      "en": "/account/extend/[booking]/success",
      "de": "/konto/verlaengern/[booking]/erfolgreich",
      "fr": "/compte/prolonger/[booking]/succes",
      "it": "/account/estendere/[booking]/successo"
    },
    "account/edit": {
      "en": "/account/edit",
      "de": "/konto/bearbeiten",
      "fr": "/compte/modifier",
      "it": "/account/modificare"
    },
    "register": {
      "en": "/register",
      "de": "/registrieren",
      "fr": "/inscription",
      "it": "/registrazione"
    },
    "cars/index": {
      "en": "/cars",
      "de": "/autos",
      "fr": "/voitures",
      "it": "/auto"
    },
    "cars/[id]/index": {
      "en": "/cars/[id]/",
      "de": "/autos/[id]/",
      "fr": "/voitures/[id]/",
      "it": "/auto/[id]/"
    },
    "cars/[id]/book": {
      "en": "/cars/[id]/book",
      "de": "/autos/[id]/buchen",
      "fr": "/voitures/[id]/reserver",
      "it": "/auto/[id]/prenotare"
    },
    "cars/[id]/success": {
      "en": "/cars/[id]/success",
      "de": "/autos/[id]/erfolgreich",
      "fr": "/voitures/[id]/succes",
      "it": "/auto/[id]/successo"
    },
    "cars/[id]/failed": {
      "en": "/cars/[id]/failed",
      "de": "/autos/[id]/fehlgeschlagen",
      "fr": "/voitures/[id]/echec",
      "it": "/auto/[id]/fallito"
    },
    "how": {
      "en": "/how-it-works",
      "de": "/so-funktionierts",
      "fr": "/comment-ca-marche",
      "it": "/come-funziona"
    },
    "about": {
      "en": "/about",
      "de": "/ueber-uns",
      "fr": "/a-propos",
      "it": "/chi-siamo"
    },
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "fr": "/contact",
      "it": "/contatto"
    },
    "faq": {
      "en": "/faq",
      "de": "/faq",
      "fr": "/faq",
      "it": "/faq"
    },
    "terms": {
      "en": "/terms",
      "de": "/agb",
      "fr": "/conditions-generales",
      "it": "/termini-e-condizioni"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "iso": "en-US",
      "files": []
    },
    {
      "code": "de",
      "iso": "de-DE",
      "files": []
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "files": []
    },
    {
      "code": "it",
      "iso": "it-IT",
      "files": []
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

