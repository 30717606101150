<template>
  <UiOverlay @close="closeOverlay()">
    <div class="bg-black-4 flex flex-col grow basis-0 sm:hidden">
      <p v-if="showSignUp" class="w-full mx-6 my-4"></p>
      <img
        v-if="!signedIn"
        src="/img/illustrations/smiley_happy.svg"
        alt=""
        class="m-auto w-24 h-24"
      />
      <img v-else src="/img/illustrations/letter.svg" alt="" class="m-auto w-24 h-24" />

      <p v-if="showSignUp" class="w-full mx-6 my-4 text-black-50">
        {{ $t('account.sign-in.no-account') }}
        <nuxtLink
          @click="
            signInVisible = false;
            signUpVisible = true;
          "
          class="text-purple no-underline cursor-pointer hover:text-opacity-70 transition-colors"
        >
          {{ $t('account.sign-up.title') }}
        </nuxtLink>
      </p>
    </div>
    <div v-if="!signedIn" class="grow m-4 flex flex-col basis-0">
      <UiOverlayClose @click="closeOverlay()" />
      <h4 class="mt-6 mb-2 sm:mt-0 text-balance">{{ $t('account.sign-in.title') }}</h4>
      <form @submit.prevent="signIn" class="flex flex-col h-full">
        <AppTextField
          class="w-full"
          :placeholder="$t('account.info.mail.placeholder')"
          :title="$t('account.info.mail.title')"
          type="email"
          v-model="email"
        >
        </AppTextField>
        <input
          type="submit"
          :value="$t('account.sign-in.title')"
          class="py-4 px-16 mt-auto bg-purple text-pink rounded-s cursor-pointer hover:scale-[.98] transition-transform"
        />
      </form>
      <p class="hidden w-full my-3 text-black-50 text-sm sm:inline-block">
        {{ $t('account.sign-in.no-account') }}
        <nuxtLink
          @click="
            signInVisible = false;
            signUpVisible = true;
          "
          class="text-purple no-underline cursor-pointer"
        >
          {{ $t('account.sign-up.title') }}
        </nuxtLink>
      </p>
    </div>
    <div v-if="signedIn" class="grow m-4 flex flex-col basis-0">
      <UiOverlayClose @click="closeOverlay()" />
      <h4 class="my-auto pb-16 sm:text-center">
        <img src="/img/illustrations/letter.svg" alt="" class="hidden m-auto w-24 h-24 sm:block" />
        {{ $t('account.sign-in.success') }}
      </h4>
    </div>
  </UiOverlay>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/Auth';
const { showSignUp } = defineProps(['showSignUp']);

const email = ref('');
const signedIn = ref(false);
const store = useAuthStore();
const { sendMagicLink } = store;

const { signInVisible, signUpVisible } = useOverlay();
const closeOverlay = () => (signInVisible.value = false);

const signIn = async () => {
  const data = await sendMagicLink(email.value);
  if (data.value) {
    signedIn.value = true;
  }
};
</script>
