<template>
  <label
    class="relative border border-black-10 rounded-s px-4 py-3 cursor-text hover:bg-black/[.015] transition-colors"
    :for="uid"
  >
    <p class="text-sm text-black-50 leading-tight select-none h-4">
      {{ title }}
    </p>
    <input
      class="w-full text-black placeholder-black-50 placeholder-opacity-50 p-0 m-0 outline-none leading-tight bg-[transparent] z-10"
      type="search"
      v-model="address"
      :placeholder="placeholder"
      :required="required"
      @input.lazy="refetchSuggestions = true"
      @blur="if (!hoveringSuggestions) suggestionsVisible = false;"
      @focus="suggestionsVisible = true"
      :id="uid"
      :disabled="disabled"
    />
    <ul
      class="absolute left-0 top-full mt-1 suggestions bg-white rounded-s text-black font-sans border-spacing-4 shadow overflow-hidden border border-black-10 w-full w-min-full suggestions z-10"
      v-if="suggestions.length && suggestionsVisible"
      @mouseenter="hoveringSuggestions = true"
      @mouseleave="hoveringSuggestions = false"
    >
      <li
        class="border-b border-black-10 px-4 py-2 cursor-pointer hover:bg-black-4"
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.place_name }}
      </li>
    </ul>
  </label>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { BookingDeliveryAndPickupAddress } from '@/types/backend';

const i18nLocale = useI18n();
const props = withDefaults(
  defineProps<{
    title: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    modelValue: any;
  }>(),
  { disabled: false, required: false },
);
const emit = defineEmits(['update:modelValue']);

const runtimeConfig = useRuntimeConfig();
const address = ref(
  props.modelValue && props.modelValue.street
    ? `${props.modelValue.street} ${props.modelValue.streetNumber}, ${props.modelValue.zipCode} ${props.modelValue.city}, ${props.modelValue.country}`
    : '',
);
const refetchSuggestions = ref(true);
const suggestions: Ref<MapboxGeocoder.Result[]> = ref([]);
const suggestionsVisible = ref(false);
const hoveringSuggestions = ref(false);
const compontentInstance = getCurrentInstance();
let uid: string = `address-input-${compontentInstance?.uid ?? 0}`;

watchThrottled(
  address,
  () => {
    fetchAddressSuggestions();
  },
  { throttle: 500 },
);

const fetchAddressSuggestions = async () => {
  if (!address.value || !refetchSuggestions.value) {
    suggestions.value = [];
    return;
  }
  const mapboxSuggestions = await $fetch<MapboxGeocoder.Results>(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address.value}.json?access_token=${runtimeConfig.public.mapboxToken}&types=address&proximity=47.367100,8.551028&language=${i18nLocale.locale.value}`,
  );
  if (mapboxSuggestions) {
    suggestionsVisible.value = true;
    suggestions.value = mapboxSuggestions.features as MapboxGeocoder.Result[];
  }
};
const selectSuggestion = (suggestion: MapboxGeocoder.Result) => {
  const suggestionFormatted: BookingDeliveryAndPickupAddress = {
    street: suggestion.text,
    streetNumber: suggestion.address ?? '',
    zipCode: suggestion.context.filter((item) => item.id.includes('postcode'))[0].text,
    city: suggestion.context.filter((item) => item.id.includes('place'))[0].text,
    country: suggestion.context.filter((item) => item.id.includes('country'))[0].text,
  };

  refetchSuggestions.value = false;
  hoveringSuggestions.value = false;
  address.value = suggestion.place_name;
  emit('update:modelValue', suggestionFormatted);
  suggestions.value = [];
};
</script>
