import { useRouteQuery } from '@vueuse/router';
import type { VehicleType, AdvancedFilter, Discount, PromotionDiscount } from '@/types/backend';

export const useSearch = () => {
  const runtimeConfig = useRuntimeConfig();

  const searchDate = useSessionStorage<(string | undefined)[]>('globalSearchDate', [], {
    mergeDefaults: true,
  });
  const vehicleTypes = useSessionStorage<VehicleType[]>('globalVehicleTypes', [], {
    mergeDefaults: true,
  });
  const advancedFilters = useSessionStorage<AdvancedFilter[]>('globalAdvancedFilters', [], {
    mergeDefaults: true,
  });

  const startDate = computed<undefined | string>(() => {
    return searchDate.value[0] ? searchDate.value[0].replace('Z', '') : undefined;
  });
  const endDate = computed<undefined | string>(() => {
    return searchDate.value[1] ? searchDate.value[1].replace('Z', '') : undefined;
  });

  const bookingDuration = computed(() => {
    if (!startDate.value || !endDate.value) return 0;
    const miliseconds = new Date(endDate.value).getTime() - new Date(startDate.value).getTime();
    return miliseconds / (1000 * 3600 * 24) + 1;
  });
  const allWheelFilter = computed(() => (advancedFilters.value.includes('awd') ? true : undefined));
  const fuelTypeFilter = computed(() =>
    advancedFilters.value.includes('electric') ? 'electric' : undefined,
  );
  const transmissionTypeFilter = computed(() =>
    advancedFilters.value.includes('automatic') ? 'automatic' : undefined,
  );

  // methods
  async function getGlobalDiscount(): Promise<PromotionDiscount> {
    // Structuring discounts into vehicle sizes
    const initialDiscounts = { S: 1, M: 1, L: 1 };
    const discounts = { S: 1, M: 1, L: 1 };
    if (!startDate.value || !endDate.value) return initialDiscounts;
    if (typeof startDate.value !== 'string') return initialDiscounts;
    try {
      // Get Discounts
      const discountArray = await $fetch<Discount[]>(
        `${runtimeConfig.public.backendBaseUrl}/promotions/calculate`,
        {
          params: { startDate: startDate.value ?? '', endDate: endDate.value ?? '' },
        },
      );
      // return discountArray.reduce((arr, d) => arr * d.discount, 1);
      // const dummyDiscount = [
      //   {
      //     _id: '65ad9a15e8d1b4b429786bd1',
      //     name: 'Normal Year',
      //     type: 'general',
      //     discount: 0.7,
      //     vehicleType: 'S',
      //     startDate: null,
      //     endDate: null,
      //     frequency: 'yearly',
      //     months: [1, 2, 3, 4, 5, 9, 10, 11],
      //     __v: 0,
      //     id: '65ad9a15e8d1b4b429786bd1',
      //   },
      //   {
      //     _id: '65ad9a15e8d1b4b429786bd1',
      //     name: 'Normal Year',
      //     type: 'general',
      //     discount: 0.8,
      //     vehicleType: 'M',
      //     startDate: null,
      //     endDate: null,
      //     frequency: 'yearly',
      //     months: [1, 2, 3, 4, 5, 9, 10, 11],
      //     __v: 0,
      //     id: '65ad9a15e8d1b4b429786bd1',
      //   },
      //   {
      //     _id: '65ad9a15e8d1b4b429786bd1',
      //     name: 'Normal Year',
      //     type: 'general',
      //     discount: 0.9,
      //     vehicleType: 'L',
      //     startDate: null,
      //     endDate: null,
      //     frequency: 'yearly',
      //     months: [1, 2, 3, 4, 5, 9, 10, 11],
      //     __v: 0,
      //     id: '65ad9a15e8d1b4b429786bd1',
      //   },
      //   {
      //     name: 'long-term-rental',
      //     type: 'long-term',
      //     discount: 0.85,
      //     frequency: 'progressive',
      //     id: 'long-term',
      //   },
      // ];

      for (const offer of discountArray) {
        // Apply the discount if the offer is applicable to all vehicle types or to a specific one.
        if (!offer.vehicleType || ['S', 'M', 'L'].includes(offer.vehicleType)) {
          const discountFactor = offer.discount;

          if (!offer.vehicleType) {
            // Apply discount to all vehicle types
            discounts['S'] *= discountFactor;
            discounts['M'] *= discountFactor;
            discounts['L'] *= discountFactor;
          } else {
            // Apply discount to specific vehicle type
            discounts[offer.vehicleType] *= discountFactor;
          }
        }
      }
      return discounts;
    } catch (error) {
      console.error(error);
      return initialDiscounts;
    }
  }
  function getRouteDate() {
    const bookingStart = useRouteQuery('start', searchDate.value[0]?.toString());
    const bookingEnd = useRouteQuery('end', searchDate.value[1]?.toString());

    return [bookingStart, bookingEnd];
  }

  function addToRoute() {
    const { name, query } = useRoute();
    const router = useRouter();
    if (!startDate.value || !endDate.value) return;
    // setRouteDate(searchDate.value[0], searchDate.value[1]);
    if (!name) return;
    router.replace({
      name: name,
      query: {
        ...query,
        start: useDateFormat(startDate.value.replace('Z', ''), 'YYYY-MM-DD').value,
        end: useDateFormat(endDate.value.replace('Z', ''), 'YYYY-MM-DD').value,
      },
    });
  }

  function removeFromRoute() {
    const { name, query } = useRoute();
    if (!name) return;
    const router = useRouter();
    router.replace({ name: name, query: { ...query, start: undefined, end: undefined } });
  }

  function setAdvancedFilter(filter: AdvancedFilter) {
    const index = advancedFilters.value.indexOf(filter);
    if (index !== -1) {
      advancedFilters.value.splice(index, 1);
    } else {
      advancedFilters.value.push(filter);
    }
  }

  function setSize(size: VehicleType) {
    const index = vehicleTypes.value.indexOf(size);
    if (index !== -1) {
      vehicleTypes.value.splice(index, 1);
    } else {
      vehicleTypes.value.push(size);
    }
  }

  function setSearchDate(start: string | null, end: string | null) {
    if (start && !isNaN(Date.parse(start))) {
      // searchDate.value[0] = new Date(start).toISOString().replace('Z', '');
      searchDate.value[0] = new Date(start).toISOString();
    }
    if (end && !isNaN(Date.parse(end))) {
      // searchDate.value[1] = new Date(end).toISOString().replace('Z', '');
      searchDate.value[1] = new Date(end).toISOString();
    }
  }

  return {
    // getters
    startDate,
    endDate,
    searchDate,
    bookingDuration,
    vehicleTypes,
    advancedFilters,
    // discount,
    allWheelFilter,
    fuelTypeFilter,
    transmissionTypeFilter,
    // methods
    getRouteDate,
    addToRoute,
    removeFromRoute,
    setAdvancedFilter,
    setSize,
    getGlobalDiscount,
    setSearchDate,
  };
};
