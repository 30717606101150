<template>
  <UiOverlay @close="closeOverlay()">
    <div v-if="!signedUp" class="grow m-4 flex flex-col basis-0">
      <UiOverlayClose @click="closeOverlay()" />
      <h4 class="mt-0 mb-4">{{ $t('account.sign-up.title') }}</h4>
      <form @submit.prevent="signUp" class="flex flex-wrap gap-4">
        <AppTextField
          class="w-1/3 grow sm:w-full"
          :placeholder="$t('account.info.first-name.placeholder')"
          :title="$t('account.info.first-name.title')"
          type="text"
          v-model="account.firstName"
        >
        </AppTextField>
        <AppTextField
          class="w-1/3 grow sm:w-full"
          :placeholder="$t('account.info.last-name.placeholder')"
          :title="$t('account.info.last-name.title')"
          type="text"
          v-model="account.lastName"
        >
        </AppTextField>
        <AppTextField
          class="w-1/3 grow sm:w-full"
          :placeholder="$t('account.info.mail.placeholder')"
          :title="$t('account.info.mail.title')"
          type="email"
          v-model="account.email"
        >
        </AppTextField>
        <vue-tel-input
          class="w-1/3 grow sm:w-full"
          v-model="account.tel"
          defaultCountry="CH"
          :dropdownOptions="{
            showFlags: false,
            showDialCodeInList: true,
            showDialCodeInSelection: true,
            showSearchBox: true,
          }"
          :inputOptions="{
            placeholder: $t('account.info.phone.placeholder'),
          }"
          :validCharactersOnly="true"
          mode="international"
          :preferredCountries="['CH', 'DE', 'AT', 'IT', 'FR', 'LI']"
        ></vue-tel-input>
        <AppNationalityField
          class="w-1/3 grow sm:w-full"
          :placeholder="$t('account.info.nationality.placeholder')"
          :title="$t('account.info.nationality.title')"
          type="text"
          v-model="account.nationality"
        ></AppNationalityField>
        <AppBirthdayField
          class="w-1/3 grow sm:w-full"
          placeholder="dd.mm.yyyy"
          :required="true"
          :title="$t('account.info.birthdate.title')"
          v-model="account.birthdate"
        ></AppBirthdayField>
        <AppAddressField
          class="w-full grow"
          :placeholder="$t('account.info.address.placeholder')"
          :title="$t('account.info.address.title')"
          v-model="account.address"
        >
        </AppAddressField>
        <input
          type="submit"
          :value="$t('account.sign-up.title')"
          class="py-4 px-16 mt-auto bg-purple text-pink rounded-s w-full cursor-pointer hover:scale-[.98] transition-transform"
        />
      </form>
      <p class="w-full my-3 text-black-50 text-sm sm:pb-7">
        {{ $t('account.sign-up.has-account') }}
        <nuxtLink
          @click="
            signInVisible = true;
            signUpVisible = false;
          "
          class="text-purple no-underline cursor-pointer hover:text-opacity-70 transition-colors"
        >
          {{ $t('account.sign-in.title') }}
        </nuxtLink>
      </p>
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full gap-4">
      <img src="/img/illustrations/heart.svg" alt="" class="w-24 h-24" />
      <h4 class="w-1/2 text-center sm:w-full sm:px-4">{{ $t('account.sign-up.success') }}</h4>
      <button
        class="py-3 px-4 w-1/5 bg-purple bg-opacity-20 text-purple rounded-s text-sm sm:w-auto hover:bg-opacity-30 transition-colors"
        @click="closeOverlay()"
      >
        {{ $t('ui.close') }}
      </button>
    </div>
  </UiOverlay>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/Auth';

const store = useAuthStore();
const { registerUser } = store;

const signedUp = ref(false);
const { signInVisible, signUpVisible } = useOverlay();
const closeOverlay = () => (signUpVisible.value = false);

const account = ref({
  firstName: <string>'',
  lastName: <string>'',
  email: <string>'',
  tel: <string>'',
  nationality: <string>'',
  birthdate: <string>'',
  address: <MapboxGeocoder.Result>{},
});

const signUp = async function () {
  const { data } = await registerUser({
    email: account.value.email,
    firstName: account.value.firstName,
    familyName: account.value.lastName,
    phoneNumber: account.value.tel,
    birthDate: account.value.birthdate,
    nationality: account.value.nationality,
    address: {
      street: account.value.address.street,
      streetNumber: account.value.address.streetNumber,
      zipCode: account.value.address.zipCode,
      city: account.value.address.city,
      country: account.value.address.country,
    },
  });
  if (data.value) {
    signedUp.value = true;
  }
};
</script>

<style scoped>
.overlay-inner {
  min-height: min(30rem, 90vh);
}
</style>
