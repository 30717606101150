<template>
  <label
    class="relative border border-black-10 rounded-s px-4 py-3 cursor-text hover:bg-black/[.015] transition-colors"
    :for="uid"
  >
    <p class="text-sm text-black-50 leading-tight select-none h-4">{{ title }}</p>
    <input
      :id="uid"
      class="w-full text-black placeholder-black-50 placeholder-opacity-50 p-0 m-0 outline-none leading-tight disabled:text-black/30 bg-[transparent] text-left"
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    />
  </label>
</template>

<script setup lang="ts">
import type { InputTypes } from '@/types/general';

withDefaults(
  defineProps<{
    title: string;
    placeholder?: string;
    type: InputTypes;
    disabled?: boolean;
    required?: boolean;
    modelValue: any;
  }>(),
  { disabled: false, required: false },
);

const instance = getCurrentInstance();
const uid = instance ? `text-input-${instance.uid.toString()}` : `text-input-${createRandomId(8)}`;
</script>

<style>
input::-webkit-date-and-time-value {
  text-align: left;
}
</style>
