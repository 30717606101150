import type { Promotion, PromotionDiscount } from '~/types/backend';

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const { data, error } = await useFetch<Promotion[]>(
    `${runtimeConfig.public.backendBaseUrl}/promotions`,
  );

  // Store the fetched data in a reactive state
  const promotionData = useState<PromotionDiscount>('promotions', () => ({ S: 1, M: 1, L: 1 }));

  if (error.value || !data.value) {
    console.error('Failed to fetch promotion data:', error.value);
    nuxtApp.provide('promotionData', promotionData.value);
    return;
  }

  if (data.value) {
    const month = new Date().getMonth() + 1;
    const currentMonthPromotions = data.value.filter((promo: Promotion) =>
      promo.months.includes(month),
    );

    const getMinDiscount = (vehicleType?: string) => {
      const filteredPromotions = !vehicleType
        ? currentMonthPromotions.filter((promo) => !promo.vehicleType)
        : currentMonthPromotions.filter((promo) => promo.vehicleType === vehicleType);
      return filteredPromotions.reduce<number>((min, promo) => Math.min(min, promo.discount), 1);
    };

    const generalDiscount = getMinDiscount(undefined);
    const vehicleTypeDiscountS = getMinDiscount('S');
    const vehicleTypeDiscountM = getMinDiscount('M');
    const vehicleTypeDiscountL = getMinDiscount('L');

    promotionData.value = {
      S: Math.min(generalDiscount, vehicleTypeDiscountS),
      M: Math.min(generalDiscount, vehicleTypeDiscountM),
      L: Math.min(generalDiscount, vehicleTypeDiscountL),
    };
  }

  // Make the data available throughout the app
  nuxtApp.provide('promotionData', promotionData.value);
});
