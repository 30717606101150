<template>
  <label
    class="relative border border-black-10 rounded-s px-4 py-3 cursor-text hover:bg-black/[.015] transition-colors"
    :for="uid"
  >
    <p class="text-sm text-black-50 leading-tight select-none h-4">{{ title }}</p>
    <input
      :id="uid"
      class="w-full text-black placeholder-black-50 placeholder-opacity-50 p-0 m-0 outline-none leading-tight disabled:text-black/30 bg-[transparent] text-left"
      inputmode="numeric"
      type="text"
      :placeholder="placeholder"
      v-model="dateString"
      @input="formatDate"
      ref="dateInput"
      aria-label="Enter date in year-month-day format"
      :required="required"
    />
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    modelValue: any;
  }>(),
  { disabled: false, required: false },
);
const instance = getCurrentInstance();
const uid = instance ? `text-input-${instance.uid.toString()}` : `text-input-${createRandomId(8)}`;

const dateInput = ref(<string | null>null);
const dateString = ref(<string | null>null);
const dateObject = ref<Date | null>(null); // Holds the Date object

const emit = defineEmits(['update:modelValue']);

function formatDate(event) {
  let value = event.target.value;
  value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
  if (value.length > 2) {
    value = value.substring(0, 2) + '.' + value.substring(2);
  }
  if (value.length > 5) {
    value = value.substring(0, 5) + '.' + value.substring(5, 9);
  }
  dateString.value = value.substring(0, 10); // Limit to DD.MM.YYYY format
  updateDateObject();
}

function updateDateObject() {
  if (!dateString.value) return;
  const parts = String(dateString.value).split('.');
  if (parts.length === 3) {
    const [day, month, year] = parts.map((part) => parseInt(part, 10));
    dateObject.value = new Date(year, month - 1, day); // Months are 0-indexed
  }
  emit('update:modelValue', dateObject.value);
}

onMounted(() => {
  if (!dateString.value && props.modelValue) {
    const parts = String(props.modelValue).split('-');
    dateString.value = `${parts[2]}.${parts[1]}.${parts[0]}`;
  }
});
</script>
