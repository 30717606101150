import { defineStore, skipHydrate, acceptHMRUpdate } from 'pinia';
import type { MagicLinkDev, User, UpdateUserPayload, CreateUserPayload } from '@/types/backend';

export const useAuthStore = defineStore('auth', () => {
  const { $toast } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const backendBaseUrl = runtimeConfig.public.backendBaseUrl;
  // state
  const authToken = useLocalStorage('authToken', '');
  const user = ref<User | null>(null);
  const loading = ref(authToken.value ? true : false);
  const { locale } = useI18n();

  // getters
  const userInfo = computed(() => user.value);
  const userEditInfo = computed((): UpdateUserPayload => {
    return {
      firstName: userInfo.value?.firstName ?? undefined,
      familyName: userInfo.value?.familyName ?? undefined,
      // TODO: user should probably be able to edit their preferred language in their profile
      phoneNumber: userInfo.value?.phoneNumber ?? undefined,
      birthDate: userInfo.value?.birthDate
        ? useDateFormat(userInfo.value.birthDate.replace('Z', ''), 'YYYY-MM-DD').value
        : undefined,
      address: userInfo.value?.address ?? undefined,
      nationality: userInfo.value?.nationality ?? undefined,
      language: (locale.value as 'de' | 'en' | 'fr' | 'it') || 'de',
    };
  });
  const userRegisterInfo = ref<CreateUserPayload>({
    firstName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
    nationality: '',
    birthDate: undefined,
    address: {},
    language: (locale.value as 'de' | 'en' | 'fr' | 'it') || 'de',
  });
  const isLoggedIn = computed(() => authToken.value);
  const hasLicense = computed(() => !!user.value?.driverLicense?.frontIMG);
  const isLoading = computed(() => loading.value);

  // actions
  async function sendMagicLink(userEmail: string) {
    const route = useRoute();
    const { data, error } = await useFetch<MagicLinkDev>(`${backendBaseUrl}/users/login`, {
      method: 'POST',
      body: {
        email: userEmail,
      },
      params: {
        redirectPath: encodeURIComponent(route.fullPath),
      },
    });

    if (error.value) {
      console.error(error.value.data);
      $toast.error(error.value.data.error || 'Login fehlgeschlagen', {
        description: error.value.data.message || 'Ein unbekannter Fehler ist aufgetreten',
      });
      useTrackEvent('login-link-error');
    } else {
      useTrackEvent('login-link-sent');
    }
    return data;
  }
  async function registerUser(body: CreateUserPayload) {
    const { data, error } = await useFetch<MagicLinkDev>(`${backendBaseUrl}/users/register`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (error.value) {
      console.error(error.value);
      $toast.error(error.value.data.error || 'Registrierung fehlgeschlagen', {
        description: error.value.data.message || 'Ein unbekannter Fehler ist aufgetreten',
      });
      useTrackEvent('registration-error', {
        error: error.value,
      });
    } else if (data.value) {
      useTrackEvent('registration-successful');
      authToken.value = data.value.token;
    }
    return { data, error };
  }

  async function updateUser(
    token: string,
    body: UpdateUserPayload,
    options: { showToast: boolean } = { showToast: false },
  ) {
    const { data, error } = await useFetch<User>(`${backendBaseUrl}/users/me`, {
      method: 'PUT',
      headers: {
        Authorization: `${token}`,
      },
      body: JSON.stringify(body),
    });
    if (error.value) {
      console.error(error.value);
    } else if (data.value) {
      user.value = data.value;
      if (options.showToast) {
        $toast.success('Profil wurde erfolgreich aktualisiert');
      }
    }
    return data;
  }

  async function fetchUser(token: string) {
    if (!token) return null;
    loading.value = true;
    const { data, error } = await useFetch<User>(`${backendBaseUrl}/users/me`, {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
      },
    });
    if (error.value) {
      loading.value = false;
      logout();
      $toast.error('Bitte erneut einloggen');
    }
    user.value = data.value;
    loading.value = false;
  }
  function login(token: string) {
    if (!token || !authToken) return;
    authToken.value = token;
    useTrackEvent('login-successful');
    $toast.success('Login erfolgreich');
  }
  function logout() {
    authToken.value = '';
    user.value = null;
    $toast.success('Erfolgreich ausgeloggt');
  }
  watchDebounced(
    authToken,
    async () => {
      if (!authToken.value) return;
      await fetchUser(authToken.value);
    },
    {
      debounce: 500,
      immediate: true,
    },
  );

  return {
    // state
    authToken: skipHydrate(authToken),
    user,

    // getters
    userInfo,
    userEditInfo,
    userRegisterInfo,
    isLoggedIn,
    hasLicense,
    isLoading,

    // actions
    sendMagicLink,
    registerUser,
    updateUser,
    login,
    logout,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
